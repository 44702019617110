
//
// VARIABLES
//

// Colors
$blue: #4183C4;

// Grays
$black: #000;
$darkerGray: #222;
$darkGray: #333;
$gray: #666;
$lightGray: #eee;
$white: #fff;

// Font stacks
$helvetica: Helvetica, Arial, sans-serif;
$helveticaNeue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$georgia: Georgia, serif;

// Fonts
//$base-font: 'Spoqa Han Sans', 'Source Sans Pro', 'Apple SD Gothic Neo', 'Nanum Barun Gothic', 'Nanum Gothic', 'Verdana', 'Arial', 'Dotum', sans-serif;
$base-font: "Lato", 'Source Sans Pro', 'Verdana', 'Arial', 'Dotum', sans-serif;
$code-font: 'Menlo', 'Courier New', 'Monaco', 'Spoqa Han Sans', monospace;

// Font sizes
$base-font-size: 22px;
$small-font-size: 18px;

// Colors
$base-color: $oc-gray-8;
$base-lighten-color: $oc-gray-6;

$text-color: $base-color;
$link-color: $oc-blue-8;
$divider-color: $oc-gray-1;

$table-border-color: $oc-gray-2;
$table-background-color: $oc-gray-1;

$blockquote-color: $oc-gray-6;
$blockquote-border-color: $oc-gray-3;

$footnote-link-border-color: $oc-gray-1;
$footnote-link-background-over-color: $oc-gray-1;

$selection-color: $oc-black;
$selection-background-color: $oc-gray-1;

$pagination-color: $oc-gray-6;
$pagination-over-color: $oc-gray-7;

$tag-index-label-color: $base-color;
$tag-index-label-background-color: $oc-gray-2;
$tag-index-count-background-color: $oc-gray-3;

$footer-border-color: $oc-gray-2;
$footer-background-color: $oc-gray-1;

// List
$li-bottom-space: 0.8em;
$li-bullets-width: 2em;
$li-line-height: 1.55;

$ul-bullets-font: inherit;
$ul-bullets-font-size: 1.4em;
$ul-bullets-font-line-height: 1.2;
$ul-bullets-right-space: .3em;

$ol-bullets-font: inherit;
$ol-bullets-font-size: 1em;
$ol-bullets-font-line-height: inherit;
$ol-bullets-right-space: .3em;

$li-child-size-ratio: 0.95;

$dt-width: 180px;
$dt-dd-space: 20px;
$dd-position: $dt-width+$dt-dd-space;

// Mobile breakpoints
@mixin mobile {
  @media screen and (max-width: 640px) {
    @content;
  }
}
